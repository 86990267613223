<template>
    <v-card style="min-height:355px;">
        <v-card-text>
            <div class="mt-1 mb-5">
                <v-form ref="form" v-model="valid" lazy-validation @submit.prevent>
                    
                    <v-container v-if="showForm=='Login'" @submit.prevent>
                        <v-row>
                            <v-col cols="12">
                                <div class="text-h5 mb-4">
                                    Log in to your account
                                </div>
                                <v-text-field v-model.trim="loginForm.email" label="Email" placeholder="you@email.com" :rules="emailRules" clearable required></v-text-field>
                                <v-text-field v-model.trim="loginForm.password" label="Password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'" :rules="[rules.required, rules.min]" placeholder="******" name="input-10-1" counter @click:append="show1 = !show1"
                                    required></v-text-field>
    
                                <!--
                                    <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate">
                                        Validate
                                    </v-btn>
        
                                    <v-btn color="error" class="mr-4" @click="reset">
                                        Reset Form
                                    </v-btn>
        
                                    <v-btn color="warning" @click="resetValidation">
                                        Reset Validation
                                    </v-btn>
                                    -->
                                <v-btn color="info" @click="login()">
                                    Continue
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-container v-else-if="showForm=='Create'">
                        <v-row>
                            <v-col cols="12">
                                <div class="text-h5 mb-4">
                                    Create an account
                                </div>
                                <v-text-field v-model.trim="signupForm.name" label="Name" placeholder="Display name" clearable required></v-text-field>
                                <v-text-field v-model.trim="signupForm.email" label="Email" placeholder="you@email.com" :rules="emailRules" clearable required></v-text-field>
                                <v-text-field v-model.trim="signupForm.password" label="Password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'" :rules="[rules.required, rules.min]" placeholder="******" name="input-10-1" counter @click:append="show1 = !show1"
                                    required></v-text-field>
                                <v-btn color="info" @click="signup()">
                                    Sign up
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-container v-else>
                        <v-row>
                            <v-col cols="12">
                                <PasswordReset v-if="showPasswordReset" @close="togglePasswordReset()"></PasswordReset>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-btn color="info" @click="showPasswordReset()" v-if="showForm == 'Login'" text>
                Forgot password
            </v-btn>
    
            <v-btn color="info" @click="showCreateAccount()" v-if="showForm == 'Login'" text>
                Create an account
            </v-btn>
    
            <v-btn color="info" @click="showLoginForm()" v-if="showForm != 'Login'" text> 
                Back to login form
            </v-btn>
    
        </v-card-actions>
    </v-card>
</template>

<script>
import PasswordReset from '@/components/PasswordReset'

export default {
    components: {
        PasswordReset
    },
    data() {
        return {
            valid: false,
            show1: false,
            rules: {
                required: value => !!value || 'Required.',
                min: v => (v && v.length >= 7) || 'Min 7 characters',
                emailMatch: () => (`The email and password you entered don't match`),
            },
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            loginForm: {
                email: '',
                password: ''
            },
            signupForm: {
                name: '',
                title: '',
                email: '',
                password: ''
            },
            showForm: 'Login'
        }
    },
    methods: {
        showLoginForm() {
            this.showForm = 'Login'
        },
        showPasswordReset() {

            this.showForm = 'Password'
        },
        showCreateAccount() {

            this.showForm = 'Create'
        },
        login() {
            this.$store.dispatch('login', {
                email: this.loginForm.email,
                password: this.loginForm.password
            })
        },
        signup() {
            this.$store.dispatch('signup', {
                email: this.signupForm.email,
                password: this.signupForm.password,
                name: this.signupForm.name,
                title: this.signupForm.title
            })
        },
        validate() {
            var isValid = this.$refs.form.validate()
            console.log("validate: ", isValid)
        },
        reset() {
            this.$refs.form.reset()
        },
        resetValidation() {
            this.$refs.form.resetValidation()
        },
    }
}
</script>