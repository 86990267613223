<template>
    <div>
        <div class="text-h5 mb-4">
            Password reset
        </div>
        <p>Enter your email to reset your password</p>
        <v-text-field v-model.trim="email" label="Email" placeholder="you@email.com" :rules="emailRules" clearable required></v-text-field>
        <v-btn color="info" @click="resetPassword()">
            Reset
        </v-btn>
        <p v-if="showSuccess">Success! Check your email for a reset link.</p>
    </div>
</template>

<script>
import { auth } from '@/firebase'

export default {
    data() {
        return {
            email: '',
            showSuccess: false,
            errorMsg: '',
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
        }
    },
    methods: {
        async resetPassword() {
            try {
                await auth.sendPasswordResetEmail(this.email)
                this.showSuccess = true
            } catch (error) {
              this.$store.dispatch('setAlert', {message: error.message, code: error.code})
            }
        }
    }
}
</script>